
import { db } from './firebaseConfig';
import { collection, addDoc } from "firebase/firestore";

const BitacoraCabPresuDB = async ({ fecha_mantencion, id_ingreso, folio_ingreso, confirmado, userAdd, userMod, fechaAdd, fechaMod, emp_id }) => {

    return await addDoc(collection(db, 'bitacoracabpresu'), {
        fecha_mantencion: fecha_mantencion,
        id_ingreso: id_ingreso,
        folio_ingreso: folio_ingreso,
        confirmado: confirmado,
        useradd: userAdd,
        usermod: userMod,
        fechaadd: fechaAdd,
        fechamod: fechaMod,
        emp_id: emp_id,
    })
}

export default BitacoraCabPresuDB;