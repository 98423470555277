/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import Alertas from './Alertas';
import BitacoraCabPresuDB from '../firebase/BitacoraCabPreDB';
import { Table } from 'semantic-ui-react';
import { auth, db } from '../firebase/firebaseConfig';
import { getDocs, collection, where, query, addDoc, writeBatch, doc, updateDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import useObtenerIngreso from '../hooks/useObtenerIngreso';
import * as FaIcons from 'react-icons/fa';
import { ContenedorProveedor, Contenedor, ContentElemenAdd, ListarProveedor, InputAdd, Boton, Titulo, BotonGuardar, Subtitulo, Overlay, ConfirmaModal, ConfirmaBtn, Boton2 } from '../elementos/General'
import { ListarEquipos } from '../elementos/CrearEquipos'
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { RiPlayListAddLine } from "react-icons/ri";
import { TbNotes } from "react-icons/tb";
import { TbNotesOff } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import moment from 'moment';
// import Swal from 'sweetalert2';

const EjecutarPresupuestoSt = () => {
  //fecha hoy
  let fechaAdd = new Date();
  let fechaMod = new Date();
  const user = auth.currentUser;
  const { users } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ingreso] = useObtenerIngreso(id);

  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});
  const [bitacoraCabPresu, setBitacoraCabPresu] = useState([]);
  const [id_cab_pre, setId_cab_pre] = useState('');
  const [bitaoraPresu, setBitaoraPresu] = useState([]);
  const [item, setItem] = useState([]);
  const [folio, setFolio] = useState('');
  const [rut, setRut] = useState('');
  const [entidad, setEntidad] = useState('');
  const [date, setDate] = useState('');
  const [telefono, setTelefono] = useState('');
  const [direccion, setDireccion] = useState('');
  const [correo, setCorreo] = useState('');
  const [familia, setFamilia] = useState('');
  const [tipo, setTipo] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [serie, setSerie] = useState('');
  const [servicio, setServicio] = useState('');
  const [buscador, setBuscardor] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [flag, setFlag] = useState(false);
  const [mostrar, setMostrar] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [mostrarAdd, setMostrarAdd] = useState(false);
  const [btnAgregarItem, setBtnAgregarItem] = useState(true);
  const [btnConfirmar, setBtnConfirmar] = useState(false);
  const idbitacora = useRef('');
  const documentoId = useRef('');

  const volver = () => {
    navigate('/serviciotecnico/asignadostecnicos');
  }

  useEffect(() => {
    if (ingreso) {
      setFolio(ingreso.folio);
      setRut(ingreso.rut);
      setEntidad(ingreso.entidad);
      setDate(ingreso.date);
      setTelefono(ingreso.telefono);
      setDireccion(ingreso.direccion);
      setCorreo(ingreso.correo);
      setFamilia(ingreso.familia);
      setTipo(ingreso.tipo);
      setMarca(ingreso.marca);
      setModelo(ingreso.modelo);
      setSerie(ingreso.serie);
      setServicio(ingreso.servicio);
    } else {
      navigate('/serviciotecnico/asignadostecnicos')
    }
  }, [ingreso, navigate])

  // Cambiar fecha
  const formatearFecha = (fecha) => {
    const dateObj = fecha.toDate();
    const formatear = moment(dateObj).format('DD/MM/YYYY HH:mm');
    // const fechaHoyF = moment(fechaHoy).format('DD/MM/YYYY HH:mm');
    return formatear;
  }

  // Detalle de Ingreso de equipo => Funcional
  const consultarPresupuestoCab = async () => {
    const pre = query(collection(db, 'bitacoracabpresu'), where('emp_id', '==', users.emp_id), where('id_ingreso', '==', id));
    const presu = await getDocs(pre);
    const existePresupuesto = (presu.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setBitacoraCabPresu(existePresupuesto);
    setId_cab_pre(existePresupuesto[0].id);
  }
  // // Detalle de Ingreso de equipo => Funcional
  // const consultarPresupuesto = async () => {
  //   const pre = query(collection(db, 'bitacorapresu'), where('emp_id', '==', users.emp_id), where('id_cab_pre', '==', id_cab_pre));
  //   const presu = await getDocs(pre);
  //   const existePresupuesto = (presu.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   setBitaoraPresu(existePresupuesto);
  // }
  // Listado de Items Test Ingreso => Funcional
  const getItem = async () => {
    const traerit = collection(db, 'itemsst');
    const dato = query(traerit, where('emp_id', '==', users.emp_id));
    const data = await getDocs(dato)
    setItem(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }
  const items = item.filter(it => it.categoria === 'SUBITEM')
  // Ordenar Listado por item =>  Funcional
  items.sort((a, b) => {
    const nameA = a.nombre;
    const nameB = b.nombre;
    if (nameA < nameB) { return -1; }
    if (nameA > nameB) { return 1; }
    return 0;
  });
  const filtroItem = () => {
    const buscar = buscador.toLocaleUpperCase();
    if (buscar.length === 0)
      return items.slice();
    const nuevoFiltro = items.filter(it => it.nombre.includes(buscar));
    return nuevoFiltro.slice();
  }
  const onBuscarCambios = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setBuscardor(target.value)
  }

  // Agregar Cabecera de BitacoraPresu / Boton Siguiente
  const addCabBitacora = async (ev) => {
    // ev.preventDefault();
    cambiarEstadoAlerta(false);
    cambiarAlerta({});
    // Filtar por docuemto de Cabecera Bitacora
    const existeCabF = bitacoraCabPresu.filter(pc => pc.confirmado === false);
    const existeCabC = bitacoraCabPresu.filter(pc => pc.confirmado === true);

    if (existeCabC.length > 0) {
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        tipo: 'error',
        mensaje: 'Ya se encuentra un presupuesto en el cual se esta trabajando'
      })
      setMostrarAdd(true);
      setBtnAgregarItem(false);
    } else if (existeCabF.length > 0) {
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        tipo: 'exito',
        mensaje: 'Continue con la generacion del presupuesto'
      });
      setMostrarAdd(true);
      setBtnAgregarItem(false);
      setFlag(!flag)
    } else {
      try {
        BitacoraCabPresuDB({
          fecha_mantencion: fechaAdd,
          id_ingreso: id,
          folio_ingreso: folio,
          confirmado: false,
          userAdd: user.email,
          userMod: user.email,
          fechaAdd: fechaAdd,
          fechaMod: fechaMod,
          emp_id: users.emp_id,
        })
        cambiarEstadoAlerta(true);
        cambiarAlerta({
          tipo: 'exito',
          mensaje: 'Proceda a realizar la mantención'
        })
        console.log('Se creo bitacora')
      } catch (error) {
        cambiarEstadoAlerta(true);
        cambiarAlerta({
          tipo: 'error',
          mensaje: error
        })
      }
    }
  }
  // // Agregar Item a Protocolo => Funcional
  // const AgregarItem = async (id_item) => {
  //   cambiarEstadoAlerta(false);
  //   cambiarAlerta({});
  //   // Consultar si Item se encuentra en Documento
  //   const item_id = itemrs.filter(it => it.id === id_item);
  //   // Validar Item en el documento de protocolo que se esta trabajando     
  //   const existePresupuesto = presupuesto.filter(doc => doc.item_id === item_id[0].id);
  //   // Buscar id de cabecera presupuesto
  //   const cabpre = query(collection(db, 'presupuestoscab'), where('emp_id', '==', users.emp_id), where('id_cab_inst', '==', id));
  //   const cabpresu = await getDocs(cabpre);
  //   const existecabPresupuesto = (cabpresu.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   if (!btnAgregarItem) {
  //     setBtnAgregarItem(true)
  //     if (existePresupuesto.length > 0) {
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'error',
  //         mensaje: 'Item ya se encuentra en este documento'
  //       })
  //     } else {
  //       try {
  //         PresupuestoDB({
  //           id_cab_pre: existecabPresupuesto[0].id,
  //           item_id: item_id[0].id,
  //           item: item_id[0].nombre,
  //           categoria: item_id[0].categoria,
  //           price: item_id[0].price,
  //           userAdd: user.email,
  //           userMod: user.email,
  //           fechaAdd: fechaAdd,
  //           fechaMod: fechaMod,
  //           emp_id: users.emp_id,
  //         });
  //         cambiarEstadoAlerta(true);
  //         cambiarAlerta({
  //           tipo: 'exito',
  //           mensaje: 'Item Agregado correctamente'
  //         })
  //         setFlag(!flag);
  //         setBtnConfirmar(false);
  //       } catch (error) {
  //         cambiarEstadoAlerta(true);
  //         cambiarAlerta({
  //           tipo: 'error',
  //           mensaje: error
  //         })
  //       }
  //     }
  //   }
  //   setTimeout(() => {
  //     setBtnAgregarItem(false);
  //   }, 2000);
  // }
  // // Borrar Items del presupuesto en proceso
  // const handleDelete = (itemId) => {
  //   setItemdelete(itemId);
  //   setShowConfirmation(true);
  // }
  // const cancelDelete = () => {
  //   setShowConfirmation(false);
  // }
  // const borrarItem = async () => {
  //   if (itemDelete) {
  //     try {
  //       await deleteDoc(doc(db, "bitacorapresu", itemDelete));
  //       setFlag(!flag);
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'exito',
  //         mensaje: 'Item eliminado exitosamente.'
  //       });
  //     } catch (error) {
  //       console.log('Error al eliminar items');
  //     }
  //   }
  //   setShowConfirmation(false);
  // }


  // // Función para actualizar documentos / Boton Confirmar
  // const actualizarDocs = async () => {
  //   cambiarEstadoAlerta(false);
  //   cambiarAlerta({});
  //   // Filtar por docuemto de Cabecera Bitacora
  //   const cab = query(collection(db, 'bitacoracabst'), where('emp_id', '==', users.emp_id), where('folio_ingreso', '==', folio), where('confirmado', '==', false));
  //   const cabecera = await getDocs(cab);
  //   const existeCab = (cabecera.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   console.log(existeCab)

  //   itemsCheck.forEach((docs, index) => {
  //     falsosCheck.current = itemsCheck.filter(ic => ic.valor === '')
  //   });
  //   itemsMedicion.forEach((docs, index) => {
  //     falsosLlenado.current = itemsMedicion.filter(ic => ic.valor === '')
  //   });
  //   itemsSeg.forEach((docs, index) => {
  //     falsosSelec.current = itemsSeg.filter(ic => ic.valor === '')
  //   });

  //   if (falsosCheck.current.length > 0) {
  //     Swal.fire(`Item checked ${falsosCheck.current.map((i) => {
  //       return i.id2;
  //     })} no puede estar vacio. Recuerde guardar antes de Confirmar`);
  //     setBtnGuardar(false);
  //     setBtnConfirmar(true);

  //   } else if (falsosLlenado.current.length > 0) {
  //     Swal.fire(`Tabla Vacio: Item ${falsosLlenado.current.map((i) => {
  //       return i.id2;
  //     })} no puede estar vacio. Recuerde guardar antes de Confirmar`);
  //     setBtnGuardar(false);
  //     setBtnConfirmar(true);

  //   } else if (falsosSelec.current.length > 0) {
  //     Swal.fire(`Tabla Seguridad: Item ${falsosSelec.current.map((i) => {
  //       return i.id2;
  //     })} no puede estar vacio. Recuerde guardar antes de Confirmar`);
  //     setBtnGuardar(false);
  //     setBtnConfirmar(true);
  //   } else {
  //     try {
  //       await updateDoc(doc(db, 'ingresostcab', id), {
  //         enproceso: '0',
  //         estado: 'CERRADO',
  //         usermod: user.email,
  //         fechamod: fechaMod
  //       });
  //     } catch (error) {
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'error',
  //         mensaje: 'Error al confirmar Mantencion:', error
  //       })
  //     }
  //     try {
  //       await updateDoc(doc(db, 'bitacoracabst', existeCab[0].id), {
  //         confirmado: true,
  //         usermod: user.email,
  //         fechamod: fechaMod
  //       });
  //     } catch (error) {
  //       cambiarEstadoAlerta(true);
  //       cambiarAlerta({
  //         tipo: 'error',
  //         mensaje: 'Error al confirmar Bitacora:', error
  //       })
  //       console.log(error)
  //     }
  //     Swal.fire('Check List de Mantención realizado con exito!').then((result) => {
  //       navigate('/serviciotecnico/asignadostecnicos')
  //     })
  //   }
  // }


  useEffect(() => {
    consultarPresupuestoCab();
    getItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, setFlag])

  return (
    <ContenedorProveedor style={{ width: '80%' }}>
      <Contenedor>
        <Titulo>Ejecutar Presupuesto</Titulo>
      </Contenedor>

      <Contenedor>
        {/* Informacion Cliente */}
        <Subtitulo style={{ fontSize: '18px' }}>Informacion Cliente</Subtitulo>
        <Table singleLine style={{ fontSize: '12px', lineHeight: '8px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Folio</Table.HeaderCell>
              <Table.HeaderCell>Rut</Table.HeaderCell>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Fecha</Table.HeaderCell>
              <Table.HeaderCell>Telefono</Table.HeaderCell>
              <Table.HeaderCell>Dirección</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{folio}</Table.Cell>
              <Table.Cell>{rut}</Table.Cell>
              <Table.Cell>{entidad}</Table.Cell>
              <Table.Cell>{date ? formatearFecha(date) : '00/00/00 00:00'}</Table.Cell>
              <Table.Cell>{telefono}</Table.Cell>
              <Table.Cell>{direccion}</Table.Cell>
              <Table.Cell>{correo}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {/* Informacion Equipo */}
        <Subtitulo style={{ fontSize: '18px' }}>Informacion Equipo</Subtitulo>
        <Table singleLine style={{ fontSize: '12px', lineHeight: '8px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Familia</Table.HeaderCell>
              <Table.HeaderCell>Tipo Equipamiento</Table.HeaderCell>
              <Table.HeaderCell>Marca</Table.HeaderCell>
              <Table.HeaderCell>Modelo</Table.HeaderCell>
              <Table.HeaderCell>Serie</Table.HeaderCell>
              <Table.HeaderCell>Servicio</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row >
              <Table.Cell>{familia}</Table.Cell>
              <Table.Cell>{tipo}</Table.Cell>
              <Table.Cell>{marca}</Table.Cell>
              <Table.Cell>{modelo}</Table.Cell>
              <Table.Cell>{serie}</Table.Cell>
              <Table.Cell>{servicio}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {
          isOpen &&
          <BotonGuardar onClick={() => {
            setMostrar(true);
            setIsOpen(false);
            setFlag(!flag);
            addCabBitacora();
          }}>Siguiente</BotonGuardar>
        }
      </Contenedor>

      {/* Listado de item agregados a presupuesto */}
      {
        mostrarAdd && (
          <>
            <Contenedor>
              <ContentElemenAdd>
                <Titulo>SubItems Agregados</Titulo>
              </ContentElemenAdd>
              <ListarEquipos>
                <Table singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>N°</Table.HeaderCell>
                      <Table.HeaderCell>Item</Table.HeaderCell>
                      <Table.HeaderCell>Categoria</Table.HeaderCell>
                      <Table.HeaderCell>Eliminar</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {/* {presupuesto.map((item, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{index + 1}</Table.Cell>
                          <Table.Cell style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.item}</Table.Cell>
                          <Table.Cell>{item.categoria}</Table.Cell>
                          <Table.Cell>
                            <MdDeleteForever
                              style={{ fontSize: '22px', color: '#69080A', marginLeft: '20px' }}
                              onClick={() => handleDelete(item.id)}
                              title='Eliminar Item'
                            />
                          </Table.Cell>
                        </Table.Row>
                      )
                    })} */}
                  </Table.Body>
                </Table>
              </ListarEquipos>
              {/* <BotonGuardar onClick={actualizarDocs} disabled={btnConfirmar}>Confirmar</BotonGuardar> */}
            </Contenedor>

            <ListarProveedor>
              <ContentElemenAdd>
                <Titulo>Listado de Items</Titulo>
              </ContentElemenAdd>
              <ContentElemenAdd>
                <FaIcons.FaSearch style={{ fontSize: '30px', color: '#328AC4', padding: '5px', marginRight: '15px' }} />
                <InputAdd
                  type='text'
                  placeholder='Buscar Item'
                  value={buscador}
                  onChange={onBuscarCambios}
                />
                {mostrar ?
                  <Boton onClick={() => {
                    setIsOpen(true)
                    setFlag(!flag)
                    setMostrar(false)
                  }}
                    style={{ fontSize: '28px', color: '#328AC4', marginTop: '5px' }}
                    title='Mostrar Listado de Items'
                  >
                    <TbNotes />
                  </Boton>
                  :
                  <Boton onClick={() => {
                    setIsOpen(false)
                    setMostrar(true)
                  }}
                    style={{ fontSize: '28px', color: '#328AC4' }}
                    title='No mostrar Listado de Items'
                  >
                    <TbNotesOff />
                  </Boton>
                }
              </ContentElemenAdd>
              {isOpen &&
                <Table singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>N°</Table.HeaderCell>
                      <Table.HeaderCell>Item</Table.HeaderCell>
                      <Table.HeaderCell>Categoria</Table.HeaderCell>
                      <Table.HeaderCell style={{ textAlign: 'center' }}>Agregar</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {filtroItem().map((item, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{index + 1}</Table.Cell>
                          <Table.Cell style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.nombre}</Table.Cell>
                          <Table.Cell >{item.categoria}</Table.Cell>
                          <Table.Cell style={{ textAlign: 'center' }}>
                            {/* <Boton disabled={btnAgregarItem} onClick={() => AgregarItem(item.id)}> */}
                              <RiPlayListAddLine style={{ fontSize: '20px', color: '#328AC4' }} title='Agregar Item' />
                            {/* </Boton> */}
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              }
            </ListarProveedor>
          </>
        )
      }

      <Alertas tipo={alerta.tipo}
        mensaje={alerta.mensaje}
        estadoAlerta={estadoAlerta}
        cambiarEstadoAlerta={cambiarEstadoAlerta}
      />
      <div>
        <BotonGuardar style={{ marginTop: '30px' }} onClick={volver} >Volver</BotonGuardar>
      </div>
    </ContenedorProveedor >
  )
}

export default EjecutarPresupuestoSt;