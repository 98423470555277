import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
//menu para admin-lm
export const DataMenuJadmin = [
  {
    title: 'Mis Equipos',
    path: 'misequipos',
    icon: <RiIcons.RiGridFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Agregar Familia',
        path: 'misequipos/agregarfamilia',
        icon: <IoIcons.IoMdAddCircleOutline />
      },
      {
        title: 'Agregar Tipo',
        path: 'misequipos/agregartipo',
        icon: <IoIcons.IoMdAddCircleOutline />
      },
      {
        title: 'Agregar Marca',
        path: 'misequipos/agregarmarca',
        icon: <IoIcons.IoMdAddCircleOutline />
      },
      {
        title: 'Agregar Modelo',
        path: 'misequipos/agregarmodelo',
        icon: <IoIcons.IoMdAddCircleOutline />
      },
    ]
  },
  {
    title: 'Mis Clientes',
    path: 'clientes',
    icon: <FaIcons.FaUserNurse />,
  },
  {
    title: 'Mantenciones',
    path: 'serviciotecnico',
    icon: <MdIcons.MdMedicalServices />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    
    subNav: [
      {
        title: 'Items',
        path: 'serviciotecnico/items',
        icon: <IoIcons.IoIosAlbums />
      },
      {
        title: 'Protocolos',
        path: 'serviciotecnico/protocolos',
        icon: <IoIcons.IoIosAlbums />
      },
    ]
  },
  {
    title: 'Servicio Tecnico',
    path: 'serviciotecnico',
    icon: <MdIcons.MdSettings />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Orden de Ingreso',
        path: 'serviciotecnico/ingreso',
        icon: <IoIcons.IoIosCreate />
      },
      {
        title: 'Items Servicio Tecnico',
        path: 'serviciotecnico/itemsst',
        icon: <IoIcons.IoIosAlbums />
      },
      {
        title: 'Protocolo Test Ingreso',
        path: 'serviciotecnico/protocolotest',
        icon: <IoIcons.IoIosApps />
      },
      {
        title: 'Asignar Ordenes',
        path: 'serviciotecnico/asignar',
        icon: <IoIcons.IoIosCreate />
      },
      {
        title: 'Mis Ordenes',
        path: 'serviciotecnico/asignadostecnicos',
        icon: <IoIcons.IoIosPaper/>
      }
    ]
  },
  {
    title: 'Reporte Final',
    path: 'reportes',
    icon: <MdIcons.MdSettings />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Mant. Preventivo',
        path: 'reportes/mantencionpreventiva',
        icon: <IoIcons.IoIosCreate />
      },
      {
        title: 'Presupuestos',
        path: 'reportes/presupuestos',
        icon: <IoIcons.IoIosAlbums />
      },
      // {
      //   title: 'Evaluación y Diagnostico',
      //   path: 'reportes/protocolotest',
      //   icon: <IoIcons.IoIosApps />
      // }
    ]
  },
  {
    title: 'Configuracion',
    path: 'configuracion',
    icon: <MdIcons.MdManageAccounts />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Usuarios y Roles',
        path: 'configuracion/registrausuarios',
        icon: <FaIcons.FaUserMd />
      },
      {
        title: 'Registrar Empresa',
        path: 'configuracion/agregarempresa',
        icon: <FaIcons.FaDeezer />
      },
      {
        title: 'Usuarios y Envios',
        path: 'configuracion/envios',
        icon: <MdIcons.MdEmail />
      }
    ]
  }
];
