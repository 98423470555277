import { UserContext } from "./UserContext"
import React, { useState, useEffect }  from "react";

export const UserProvider = ({ children })=>{
    const [users, setUsers] = useState();

    useEffect(() => {
        const storedUser = localStorage.getItem('users');
        if (storedUser) {
            setUsers(JSON.parse(storedUser));
        }
    }, []);
    
    return(
        <UserContext.Provider value={{ users,setUsers}}>
            { children }
        </UserContext.Provider>
    )
}